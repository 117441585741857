import {
  Link,
  useMatches,
  useNavigate,
  useRouteLoaderData,
} from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { forwardRef, useState } from "react";
import { Modal } from "react-daisyui";
import { useTranslation } from "react-i18next";

import Button from "~/components/atoms/Button";
import { Icon } from "~/components/atoms/Icons";
import IconClose from "~/components/atoms/Icons/IconClose";
import RegisterIcon from "~/components/atoms/Icons/RegisterIcon";
import Typo from "~/components/atoms/Typo";
import SalesTeamInfo from "~/components/molecules/SalesTeamInfo";
import ModalTop from "~/components/templates/ModalTop";
import useAppState from "~/stores";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import extractNumbers from "~/utilities/helpers/extractNumbers";
import { formatPhoneNumber } from "~/utilities/helpers/formatPhoneNumber";

export interface ContactUsButtonProps {
  label?: string;
  url?: string | null;
  variant?: "button" | "icon";
}

export type Ref = HTMLButtonElement;

const ContactUsButton = forwardRef<Ref, ContactUsButtonProps>(
  ({ label = "", url = "", variant = "button" }, ref) => {
    const { t } = useTranslation();
    const matches = useMatches();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { appointmentOpen, setAppointmentOpen } = useAppState(
      (state) => state
    );
    const {
      phoneNumber = "",
      whatsapp = "",
      salesTeamInfo = "",
    } = { ...(useRouteLoaderData("root") as IRouteLoaderData) };
    const navigate = useNavigate();

    const isProjectPage = matches.some(
      (match) =>
        match.pathname.startsWith(`${Slug.PROJECTS}/`) &&
        match.pathname !== Slug.PROJECTS
    );

    const handleToggleModal = () => {
      if (!isEmpty(url)) {
        url && navigate(url);
      } else {
        setIsModalOpen(!isModalOpen);
      }
    };

    const handleClickPhoneNumber = (
      event: React.MouseEvent<HTMLAnchorElement>
    ) => {
      event.preventDefault();
      window.location.href = `facetime:${phoneNumber}`;
    };

    const whatsappUrl = whatsapp
      ? `https://api.whatsapp.com/send?phone=${extractNumbers(whatsapp)}`
      : "";

    const toggleAppointment = () => {
      if (isProjectPage) {
        setAppointmentOpen(!appointmentOpen);
      } else {
        handleToggleModal();
      }
    };

    return (
      <>
        {variant === "button" && (
          <>
            <Button
              ref={ref}
              variant="destructive"
              className="bg-contactUsButtonBackground text-contactUsButtonText outline-none hover:bg-contactUsButtonBackgroundHover lg:hidden"
              onClick={() => toggleAppointment()}
            >
              {label || t("register_now")}
            </Button>
            <button
              className="hidden items-center justify-start gap-1 lg:flex"
              onClick={() => toggleAppointment()}
            >
              <Icon
                TypeIcon={RegisterIcon}
                width={14}
                height={14}
                fill={ETypoColor.BUTTON_CONTACT_US}
                className=":hover-bg-opacity-100 h-[24px] w-[24px] translate-x-px rounded-full border border-contactUsButtonIcon bg-contactUsButtonIcon px-0 text-white hover:bg-opacity-100"
              />
              <Typo
                tag={ETypoTag.P}
                variant={ETypoVariant.HEADER_14}
                color={ETypoColor.HEADER_TEXT}
                className="w-fit truncate font-bold"
              >
                {label || t("register_now")}
              </Typo>
            </button>
          </>
        )}

        {variant === "icon" && (
          <button
            className=" items-center justify-start"
            onClick={handleToggleModal}
          >
            <Icon
              TypeIcon={EIcon.Phone}
              width={28}
              height={28}
              className=" :hover-bg-opacity-100 h-[56px] w-[56px] translate-x-px rounded-full bg-contactUsButtonIcon shadow-phone-floating-button hover:bg-opacity-100"
            />
          </button>
        )}

        <ModalTop
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          closeOnOutsideClick={true}
          className="flex flex-col gap-6 rounded-3xl bg-contactUsModalBackground p-8 lg:gap-5 lg:rounded-xl lg:px-[18px] lg:py-6"
          backdropClassName="bg-contactUsModalBackdrop"
        >
          <Modal.Header className="mb-0 pb-0 font-bold">
            <div className="flex flex-row items-center justify-between gap-6">
              <Typo
                variant={ETypoVariant.HEADER_32}
                className="lg:text-sub-title-20"
                tag={ETypoTag.SPAN}
                color={ETypoColor.CONTACT_US}
              >
                {t("register_now")}
              </Typo>

              <IconClose onClick={handleToggleModal} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-col gap-4 overflow-hidden">
              <div className="flex justify-between gap-8 lg:flex-col lg:gap-3">
                {phoneNumber && (
                  <Link
                    to={`tel:${phoneNumber}`}
                    className="justify-left flex flex-1 flex-nowrap items-center gap-3"
                  >
                    <Icon
                      TypeIcon={EIcon.GreenPhoneIcon}
                      height={24}
                      width={24}
                    />
                    <Typo
                      variant={ETypoVariant.HEADER_18}
                      tag={ETypoTag.SPAN}
                      color={ETypoColor.CONTACT_US}
                      className="whitespace-nowrap"
                    >
                      {formatPhoneNumber(phoneNumber)}
                    </Typo>
                  </Link>
                )}
                {whatsapp && (
                  <Link
                    to={whatsappUrl}
                    className="justify-left flex flex-1 flex-nowrap items-center gap-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon
                      TypeIcon={EIcon.GreenCirclePhoneIcon}
                      height={24}
                      width={24}
                    />
                    <Typo
                      variant={ETypoVariant.HEADER_18}
                      tag={ETypoTag.SPAN}
                      color={ETypoColor.CONTACT_US}
                      className="whitespace-nowrap"
                    >
                      {formatPhoneNumber(whatsapp)}
                    </Typo>
                  </Link>
                )}
              </div>
              {salesTeamInfo && <SalesTeamInfo isDisplayLogo={false} />}
            </div>
          </Modal.Body>
        </ModalTop>
      </>
    );
  }
);

ContactUsButton.displayName = "ContactUsButton";

export default ContactUsButton;
