export function formatPhoneNumber(number: string): string {
  // Extract the country code and the main number
  const countryCode = number.slice(0, 3);
  const mainNumber = number.slice(3);

  // Format the main number by adding a space after every 4 digits
  const formattedMainNumber =
    mainNumber.slice(0, 4) + " " + mainNumber.slice(4);

  // Combine the country code with the formatted main number
  return `${countryCode} ${formattedMainNumber}`;
}
