import { useRouteLoaderData } from "@remix-run/react";
import { useTranslation } from "react-i18next";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SalesTeamInfoProps {
  isDisplayLogo?: boolean;
}

const SalesTeamInfo: React.FC<SalesTeamInfoProps> = ({
  isDisplayLogo = true,
}) => {
  const { t } = useTranslation();
  const { salesTeamInfo, userConfig } = useRouteLoaderData(
    "root"
  ) as IRouteLoaderData;
  const { photo } = userConfig;
  return (
    <div className="flex flex-row flex-wrap items-center justify-start gap-12 lg:gap-6">
      <div className="flex flex-col gap-3">
        <div className="flex gap-1">
          <Typo
            variant={ETypoVariant.HEADER_18}
            tag={ETypoTag.SPAN}
            color={ETypoColor.CONTACT_US}
            className="font-bold"
          >
            {t("sales_team.heading")}
          </Typo>
        </div>
        <div className="flex gap-1">
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.CONTACT_US}
          >
            {t("sales_team.agent_name")}
          </Typo>
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.CONTACT_US}
          >
            {salesTeamInfo.name}
          </Typo>
        </div>
        <div className="flex gap-1">
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.CONTACT_US}
          >
            {t("sales_team.cea_reg_no")}
          </Typo>
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.CONTACT_US}
          >
            {salesTeamInfo.registrationNumber}
          </Typo>
        </div>
        <div className="flex gap-1">
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.CONTACT_US}
          >
            {t("sales_team.agency_name")}
          </Typo>
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.CONTACT_US}
          >
            {salesTeamInfo.currentEa}
          </Typo>
        </div>
        <div className="flex gap-1">
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.CONTACT_US}
          >
            {t("sales_team.agency_license_no")}
          </Typo>
          <Typo
            variant={ETypoVariant.HEADER_16}
            tag={ETypoTag.SPAN}
            color={ETypoColor.CONTACT_US}
          >
            {salesTeamInfo.licenseNumber}
          </Typo>
        </div>
      </div>
      {photo && isDisplayLogo && (
        <ImageResponsive
          imageData={photo}
          alt="Brand Logo"
          className="h-auto max-w-[200px] origin-left"
          pictureClassName="h-fit w-fit max-w-[200px]"
          zoom={1}
          displayDisclaimer={false}
        />
      )}
    </div>
  );
};

export default SalesTeamInfo;
