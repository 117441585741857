import FacebookDarkIcon from "~/components/atoms/Icons/FacebookDarkIcon";
import FacebookLightIcon from "~/components/atoms/Icons/FacebookLightIcon";
import InstagramDarkIcon from "~/components/atoms/Icons/InstagramDarkIcon";
import InstagramLightIcon from "~/components/atoms/Icons/InstagramLightIcon";
import TiktokDarkIcon from "~/components/atoms/Icons/TiktokDarkIcon";
import TiktokLightIcon from "~/components/atoms/Icons/TiktokLightIcon";
import { EIcon } from "~/utilities/enums/Icons";
import { ESocialLinks } from "~/utilities/enums/socialLinks";

const SOCIAL_LIGHT_ICONS: Record<ESocialLinks, EIcon> = {
  [ESocialLinks.FACEBOOK]: FacebookLightIcon,
  [ESocialLinks.INSTAGRAM]: InstagramLightIcon,
  [ESocialLinks.TIKTOK]: TiktokLightIcon,
};

const SOCIAL_DARK_ICONS: Record<ESocialLinks, EIcon> = {
  [ESocialLinks.FACEBOOK]: FacebookDarkIcon,
  [ESocialLinks.INSTAGRAM]: InstagramDarkIcon,
  [ESocialLinks.TIKTOK]: TiktokDarkIcon,
};

export { SOCIAL_DARK_ICONS, SOCIAL_LIGHT_ICONS };
